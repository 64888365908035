<template>
  <li class="friend" :class="friend.state">

    <Header :friend="friend"/>

    <Footer :friend="friend"/>

    <Actions :friend="friend"/>

  </li>
</template>

<script>
import Header from '@/components/friends/friend/Header.vue'
import Footer from '@/components/friends/friend/Footer.vue'
import Actions from '@/components/friends/friend/Actions.vue'

export default {
  name: 'Friend',

  components: {
    Header,
    Footer,
    Actions
  },

  props: {
    friend: Object
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friend {
  background-color: $off-white;
  width: 100%;
  border-radius: 4px;
  padding: 16px 12px;
  position: relative;
  @include box-shadow;
  display: grid;
  grid-gap: 12px;
  grid-template-areas: "header actions" "footer footer";
  grid-template-columns: 1fr auto;

  &:not( :first-child ) {
    margin-top: $page-padding;
  }
}
</style>
