
<template>
  <div class="friend-header">

    <router-link :title="name" class="profile" :alt="name" :style="style" :to="{ name: 'users', params: { id: user }}">
      <svg v-if="icon" v-html="icon"/>
    </router-link>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FriendHeader',

  props: {
    friend: Object
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    style() {
      return this.$Utils.User.image( this.friend.image, this.friend.color )
    },

    user() {
      const requestor = this.friend.requestor
      const requestee = this.friend.requestee
      return this.me === requestor ? requestee : requestor
    },

    name() {
      return this.$Utils.User.name( this.friend )
    },
    
    icon() {
      if ( this.friend.image ) return
      return this.$Utils.User.icon( this.friend.icon )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friend-header {
  display: grid;
  grid-area: header;
  grid-gap: 16px;
  grid-template-rows: auto 1fr;
  @include flex;
  width: 100%;

  a {
    margin-left: 0;
    cursor: pointer;
  }
}
</style>
