<template>
  <div class="friend-footer">

    <router-link :to="{ name: 'users', params: { id: user }}">
      <p class="name">{{ name }}</p>
    </router-link>

    <p class="date short" :title="date">{{ short }}</p>
    <p class="date long" :title="date">{{ long }}</p>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FriendFooter',

  props: {
    friend: Object
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    accepted() {
      return this.friend.state.toUpperCase() === 'ACCEPTED'
    },

    date() {
      return this.accepted ? this.friend.updatedAt : this.friend.createdAt
    },

    duration() {
      return this.$Utils.Filters.duration( this.date )
    },

    short() {
      return `${ this.duration } ago`
    },

    long() {
      return this.accepted ? `Friends for ${ this.duration }` : `Sent ${ this.duration } ago`
    },

    user() {
      const requestor = this.friend.requestor
      const requestee = this.friend.requestee
      return this.me === requestor ? requestee : requestor
    },

    name() {
      return this.$Utils.User.name( this.friend )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friend-footer {
  position: relative;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-area: footer;

  a {
    display: grid;
  }

  p {
    font-size: 16px;

    &.name {
      padding-right: 16px;
      @include ellipsis;

      @media ( max-width: $mobile ) {
        font-size: 14px;
      }
    }

    &.date {
      font-size: 12px;
      height: 16px;
      margin-top: auto;

      &.short {
        display: none;

        @media ( max-width: $mobile ) {
          display: block;
        }
      }

      &.long {
        @media ( max-width: $mobile ) {
          display: none;
        }
      }
    }
  }
}
</style>
