<template>
  <ul class="friends-list">

    <Friend v-for="friend in friends.filter( f => f.state === state )" :key="friend.id" :friend="friend"/>

  </ul>
</template>

<script>
import Friend from '@/components/friends/friend/Container.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FriendsList',

  props: {
    state: String
  },

  components: {
    Friend
  },

  computed: {
    ...mapGetters( 'Friends', {
      friends: 'friends'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friends-list {
  padding: $page-padding;
}
</style>
