
<template>
  <div class="friend-actions">

    <!-- Reject -->
    <button role="button" v-if="!accepted" @click="r" class="submit" alt="Reject">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><p>Reject</p></span>
    </button>

    <!-- Accept -->
    <button role="button" v-if="!accepted" @click="a" class="submit" alt="Accept">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><p>Accept</p></span>
    </button>

    <!-- Delete -->
    <button role="button" v-if="accepted" @click="d" class="submit reject" alt="Delete">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><p>Delete</p></span>
    </button>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FriendActions',

  props: {
    friend: Object
  },

  computed: {
    accepted() {
      return this.friend.state.toUpperCase() === 'ACCEPTED'
    }
  },

  methods: {
    ...mapActions( 'Friends', {
      reject: 'reject',
      accept: 'accept',
      delete: 'delete'
    }),

    r() {
      this.reject( this.friend )
    },

    a() {
      this.accept( this.friend )
    },

    d() {
      this.delete( this.friend )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friend-actions {
  text-align: right;
  grid-area: actions;

  button {
    float: right;

    &:not( :first-child ) {
      margin-right: 12px;
    }

    .content {
      padding: 8px 14px;

      @media ( max-width: $tablet ) {
        p { font-size: 13px; }
      }
    }
  }
}
</style>
