<template>
  <div class="friends">

    <div class="states">
      <button role="button" alt="Accepted" @click="state = 'ACCEPTED'" :class="{ active: accepted }"><p>Accepted</p></button>
      <button role="button" alt="Awaiting" @click="state = 'AWAITING'" :class="{ active: awaiting }"><p>Requests</p></button>
    </div>

    <List :state="state"/>

  </div>
</template>

<script>
import List from '@/components/friends/Container.vue'

export default {
  name: 'Friends',

  data() {
    return {
      state: 'ACCEPTED'
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    List
  },

  computed: {
    accepted() {
      return this.state.toUpperCase() === 'ACCEPTED'
    },

    awaiting() {
      return this.state.toUpperCase() === 'AWAITING'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friends {
  position: relative;
  text-align: center;
  height: auto;
  max-width: $max-width;
  margin: 0 auto;
  display: grid;
  overflow-y: scroll;
  grid-template-rows: auto 1fr;

  .states {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    width: 100%;
    background-color: $off-white;
    border-left: 1px solid $med-light-grey;
    border-right: 1px solid $med-light-grey;
    border-bottom: 1px solid $med-light-grey;
    align-items: center;
    //background: radial-gradient( ellipse at right top, lighten( $primary-color, 6 ) 0%, $primary-color 56%, darken( $primary-color, 6 ) 100% );
    background-color: $primary-color;

    button {
      border-radius: 0;
      border: none;
      padding: 16px 8px 0 8px;
      
      p {
        font-size: 18px;
        font-weight: bolder;
        text-align: center;
        width: 100px;
        font-family: $font;
        color: $off-white;
        position: relative;
        height: 100%;
        display: inline-block;
        padding: 12px 0;

        @media ( max-width: $mobile ) {
          font-size: 14px;
        }
      }

      //Active bar
      &.active {
        p::after {
          left: 0;
          bottom: 0;
          right: 0;
          height: 4px;
          width: 100%;
          background-color: $off-white;
          content: '';
          position: absolute;
        }
      }

      //Seperator
      &:not( :first-child ) {
        &::after {
          left: 0;
          top: 40%;
          bottom: 10%;
          height: 50%;
          width: 1px;
          background-color: $off-white;
          content: '';
          position: absolute;
        }
      }
    }
  }
}
</style>
