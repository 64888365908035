<template>
  <div class="friends-list">

    <input type="text" v-model="search" placeholder="Search" @input="type"/>

    <div class="scroller" ref="scroller" v-on:scroll="scrolling">
      
      <!-- Content -->
      <List v-if="showFriends" :state="state"/>

      <!-- Error and Loading -->
      <div v-else class="retry">
        <div>

          <h2 v-if="showError" class="bk">{{ error || 'No friends' }}</h2>
          <button role="button" v-if="showError" @click="reload" alt="Reload" class="submit circle retry">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><img :src="icons.reload" alt="Reload"/></span>
          </button>
          <Loading v-if="loading"/>

        </div>
      </div>

      <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
        <p>{{ label }}</p>
        <img v-if="!loading" :src="icons.load" alt="Load more"/>
      </button>

    </div>
  </div>
</template>

<script>
import List from '@/components/friends/List.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FriendsState',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      search: '',
      searching: false,
      debounce: null,
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' ),
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  props: {
    state: String
  },

  components: {
    List,
    Loading
  },

  computed: {
    ...mapGetters( 'Friends', {
      isEmpty: 'isEmpty',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'friends'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showFriends() {
      return !this.error && !this.isEmpty && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showFriends
    },

    showError() {
      return ( this.error || this.isEmpty ) && !this.loading
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Friends', {
      fetch: 'get'
    }),

    async load() {
      if (( !this.canLoadMore && !this.searching ) || this.loading ) return

      this.error = null
      this.loading = true

      const offset = ( this.searching ) ? 0 : this.offset
      const count = await this.fetch({ offset, search: this.search, state: this.state }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.searching = false
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
      
      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    },

    async type() {
      clearTimeout( this.debounce )
      this.debounce = setTimeout( async () => {
        this.searching = true
        await this.load()
      }, 300 )
    }
  },

  watch: {
    state: {
      immediate: true,
      async handler() {
        this.loading = false
        this.loaded = false
        this.error = null
        this.search = ''
        this.searching = false
        this.canLoadMore = true
        this.debounce = null
        await this.load()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.friends-list {
  position: relative;
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: scroll;

  .scroller {
    overflow-y: scroll;
    position: relative;
  }

  button.pagination {
    margin-bottom: 16px;
  }

  input {
    box-shadow: none;
    border: 1px solid $med-light-grey;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media ( max-width: $tablet ) {
      border-radius: 0;
    }
  }
}
</style>
